.title-container {
	position: relative;
	width: 36%;
	margin-left: -16%;
	top: 77.5%;

	img {
		display: block;
		width: 100%;
	}
}
