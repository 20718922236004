.react-responsive-modal-overlay {
	padding: 0;

	&::before {
		// content: "";
		// position: absolute;
		// left: 0;
		// top: 0;
		// width: 100%;
		// height: 100%;
		// background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/preview.jpeg") center
		// 	top/100% no-repeat;
		// z-index: 999;
		// opacity: 0.5;
	}
}

.modal-base {
	--color: #ffffff;
	//   --modal-height: 110vmin;
	// --modal-img: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/Pop Up/bg_details_and_history.png");
	--modal-img: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/popup/bg_detail.png");
	max-width: 1200px;
	background: var(--modal-img) top center no-repeat;
	background-size: 100%;
	box-shadow: none;
	padding: 0;
	// overflow: hidden;
	//   margin-bottom: 8vmin;

	* {
		color: var(--color);
	}

	.modal-content {
		max-width: 100%;
		padding: 13vmin 2vmax 18vmin 5vmax;
		height: 100%;

		.title {
			height: 12%;
			display: flex;
			align-items: center;

			img {
				height: 100%;
			}
		}

		.content {
			// height: 70%;
			// overflow-y: auto;
			// font-size: 1.5vmax;
			// margin: 0 1.5vmax;
			// padding-right: 1.5vmax;
		}
	}

	ul,
	ol {
		margin: 0;
		line-height: 1.3;
		padding-left: 2vmax;
		padding-right: 1vmax;
	}
}

.closeBtn {
	--close-dimension: 5%;
	--close-btn-img: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/Pop Up/close.png");
	background: var(--close-btn-img) center no-repeat;
	background-size: contain;
	width: var(--close-dimension);
	height: var(--close-dimension);
	top: 23%;
	right: -6%;

	svg {
		visibility: hidden;
	}
}

@import "history";
@import "confirm";
@import "success";
@import "error";
@import "info";
@import "discount";
@import "confirmDiscount";
