.user-details {
	display: flex;

	.user {
		--rope: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/draw_rope.png");
		width: calc(100% / 4);
		position: relative;
		padding-top: 2.5%;

		img {
			position: relative;
			z-index: 1;
		}

		&::before,
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: var(--rope) center no-repeat;
			background-size: cover;
			position: absolute;
			top: 0;
		}

		> * {
			flex-grow: 0;
			flex-shrink: 0;
		}

		img {
			width: 100%;
		}

		> .wrapper {
			position: relative;
		}

		span {
			position: absolute;
			transform: translate(-50%, -50%);
			font-size: 0.22678rem;
			font-family: Bold;
			color: #fff;
			z-index: 1;
		}

		&.token {
			span {
				left: 55%;
				top: 56%;
			}
		}

		&.voucher {
			span {
				left: 58%;
				top: 56%;
			}
		}

		&.name {
			width: calc(100% / 2);

			&::before,
			&::after {
				width: 50%;
				z-index: 0;
			}

			&::before {
				left: 0;
			}

			&::after {
				right: 0;
			}

			span {
				left: 50%;
				top: 56%;
				color: var(--universal-color);
				white-space: nowrap;
			}
		}
	}
}
