.error {
	--modal-img: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/popup/bg_confirm.png");
	width: 4.66rem;
	height: 5.55rem;

	.modal-content {
		// padding-top: 15vmin !important;
		// padding-bottom: 14vmin !important;
		//   padding: 25vmin 0 0 !important;
		padding: 57% 5% 20%;
		// padding: 0 5%;
	}
	.closeBtn {
		--close-dimension: 7%;
		top: 38%;
		right: -12%;
	}

	.modal-content {
		// padding-top: calc(var(--modal-height) - 70%);

		.content {
			height: 100% !important;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding-right: 0;

			* {
				color: var(--color);
			}

			h1 {
				font-size: 2vmax;
				margin-bottom: 1vmax;
				font-family: Bold;
			}

			p {
				text-align: center;
				font-size: 1.5vmax;
				font-weight: bold;
				line-height: 1.2;
			}
		}
	}
}
