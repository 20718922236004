.landing-discount {
	--landing-discount-bg: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/BG_Liliana.jpg");
	background: var(--landing-discount-bg);
	background-size: 100%;
	background-position: center;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	padding: 2vmax;
	.left {
		flex-basis: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&-btn-music {
			z-index: 2;
			flex-basis: 10%;
			cursor: pointer;
			img {
				width: 100%;
			}
		}
		&-title {
			flex-basis: 70%;
			img {
				width: 100%;
			}
		}
	}
	.right {
		flex-basis: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&-discount {
			width: 70%;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 100%;
			}
			margin-bottom: 5vmin;
			p {
				font-size: 8vmax;
				font-weight: bold;
				color: var(--universal-color);
				// color: #AB3B4D;
				position: absolute;
			}
		}
		&-btn-get {
			width: 60%;
			z-index: 2;
			cursor: pointer;
			img {
				width: 100%;
			}
			&.disabled {
				pointer-events: none;
				filter: grayscale(1);
			}
		}
	}
}
