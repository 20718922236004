$base-font-family: arial, sans-serif;
$bmg-font-family: "Roboto", sans-serif; // bmg
$bmg-font-family-2: "Passion One", cursive; // bmg
$zh-font-family: "Noto Sans TC", $base-font-family;
$vnru-font-family: "Bebas Neue Number", "Yanone Kaffeesatz", $base-font-family;
$my-font-family: "Zawgyi-One", "Padauk", $base-font-family;

$ui-width: 1334;
$ui-height: 660;
$url_image: "https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/dutyshopv2";

@import "mixins";
@import "functions";
