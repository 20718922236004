@font-face {
    font-family: Regular;
    src: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/fonts/BEAUFORTFORLOL-REGULAR.OTF");
}

@font-face {
    font-family: Bold;
    src: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/fonts/BEAUFORTFORLOL-BOLD.OTF");
}

@font-face {
    font-family: Bold-Italic;
    src: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/fonts/BEAUFORTFORLOL-BOLDITALIC.OTF");
}

@font-face {
    font-family: Italic;
    src: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/fonts/BEAUFORTFORLOL-ITALIC.OTF");
}

@font-face {
    font-family: Medium-Italic;
    src: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/fonts/BEAUFORTFORLOL-MEDIUMITALIC.OTF");
}