.landing {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;

    img {
        width: 100%;
    }
}