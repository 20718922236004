.button-container {
	display: flex;
	justify-content: center;
	align-content: center;
	position: relative;
	top: 3%;

	&.disabled,
	.disabled {
		filter: grayscale(1);
		pointer-events: none;
	}

	.buttons {
		width: 73%;
		position: relative;
		text-align: center;

		.center {
			display: inline-block;
			position: relative;
			width: 70%;
			z-index: 2;
			margin: 0 auto;

			img {
				width: 100%;
			}

			.txt-price {
				position: absolute;
				left: 34%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				flex-direction: column;
				justify-content: center;
				span {
					font-size: 1.8vmax;
					font-family: Bold;
					color: #fee7a2;
				}
				.discount-price {
					font-size: 1.2vmax;
					color: #fff;
					text-decoration: line-through;
					text-decoration-color: #7b4e3f;
					text-decoration-thickness: 3px;
				}
			}
		}

		.discount {
			--offset: -7%;
			position: absolute;
			width: 53%;
			top: 49%;
			transform: translateY(-50%);
			right: var(--offset);
			img {
				width: 100%;
			}
		}

		.edge {
			--offset: -4%;
			position: absolute;
			width: 53%;

			&:first-child,
			&:last-child,
			.discount-price,
			.notif {
				position: absolute;
				top: 49%;
				transform: translateY(-50%);
			}

			&:first-child {
				left: calc(var(--offset) + 1%);
			}

			&:last-child {
				right: var(--offset);
			}
			.discount-price {
				right: 20%;
				font-weight: bold;
				font-size: 2.2vmax;
				color: #fee7a2;
			}
			.notif {
				top: 28%;
				right: 4%;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 1.5vmax;
				}
				.number {
					position: absolute;
					top: 10%;
					font-size: 1vmax;
				}
			}
			img {
				width: 100%;
			}
		}
	}
}
