.history {
	.modal-content .content {
		padding-right: 0.5vmax;
	}

	.table {
		.row {
			display: flex;

			.col {
				font-size: 3vmin;
				padding: 0.5vmax 0;
				border: 0.1rem solid var(--color);
				color: var(--color);
				text-align: center;
				flex-grow: 0;

				&:nth-child(1) {
					width: 10%;
				}

				&:nth-child(2) {
					width: 40%;
					border-left: 0 !important;
					border-right: 0 !important;
				}

				&:nth-child(3) {
					width: 50%;
				}
			}
		}

		.thead .row .col {
			font-size: 3.2vmin;
			font-family: Bold;
		}

		.tbody {
			.row {
				.col {
					border-top: 0 !important;
					font-size: 1.2vmax;
				}

				&:not(:last-child) {
					.col {
						border-bottom: 0 !important;
					}
				}
			}
		}
	}
}
