.wp_pop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  overflow: hidden;
}

.wp_content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.wp_pop_img {
  width: 25vmax;
  height: 16vmax;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  animation: rotateA 1.5s ease infinite alternate;
}

.wp_tips {
  width: 60%;
  font-size: 2vmax;
  color: #FFFFFF;
  text-align: center;
  margin: 6vmax auto 0;
}

@keyframes rotateA {
  0% {
    transform: rotate(0deg); }
  30% {
    transform: rotate(0deg); }
  70% {
    transform: rotate(-90deg); }
  100% {
    transform: rotate(-90deg); } }

@media screen and (max-width: 820px) and (orientation: portrait) {
  .portrait {
    display: block; 
  } 
}

@media screen and (max-width: 820px) and (orientation: landscape) {
  .landscape {
    display: block;
  } 
}

@media screen and (min-width: 820px) and (orientation: landscape) {
  .desktop_landscape {
    display: block; } }