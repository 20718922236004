.info {
	// width: 56%;
	width: 6.8rem;
	// height: 5.5rem;
	height: 100%;
	// outline: 1px solid red;

	.modal-content {
		padding: 20% 5% 16%;
		display: flex;
		flex-direction: column;
	}
	.closeBtn {
		top: 15%;
		right: -9%;
		--close-dimension: 6%;
	}

	.btn-group {
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		.tab {
			width: 25%;
			display: flex;

			img {
				align-self: center;
				width: 100%;
			}
		}
	}
	.content {
		margin-top: 3%;
		// height: 68%;
		height: 3rem;
		overflow: auto;

		.event-period {
			font-size: 0.2rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 2%;
		}

		.rules {
			font-size: 0.15rem;
			height: 85%;

			ul {
				height: 100%;
				overflow-y: scroll;
				list-style-type: decimal;

				li {
					margin-bottom: 1%;
				}
			}
		}
	}

	.table {
		.row {
			display: flex;

			.col {
				font-size: 3vmin;
				padding: 0.5vmax 0;
				border: 0.01rem solid var(--color);
				color: var(--color);
				text-align: center;
				flex-grow: 0;

				&:nth-child(1) {
					width: 10%;
				}

				&:nth-child(2) {
					width: 40%;
					border-left: 0 !important;
					border-right: 0 !important;
				}

				&:nth-child(3) {
					width: 50%;
				}
			}
		}

		.thead .row .col {
			font-size: 3.2vmin;
			font-family: Bold;
		}

		.tbody {
			.row {
				.col {
					border-top: 0 !important;
					font-size: 1.2vmax;
				}

				&:not(:last-child) {
					.col {
						border-bottom: 0 !important;
					}
				}
			}
		}
	}
}
