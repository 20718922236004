.confirm-discount {
	--modal-img: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/popup/bg_confirm.png");
	width: 4.66rem;
	height: 5.55rem;
	background-position: top center;
	background-size: contain;
	// margin-bottom: 12vmin;

	.closeBtn {
		--close-dimension: 7%;
		top: 38%;
		right: -12%;
	}

	.modal-content {
		// padding: 42vmin 4vmax 0 !important;
		// padding: 24vmin 0vmax 8vmin 0vmax !important;
		// padding: 62% 0 48%;
		padding: 62% 0 20%;
		display: flex;
		justify-content: center;
		align-items: center;

		.content {
			text-align: center;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			// padding: 6vmin 0 2vmin 2vmax !important;

			.question {
				display: flex;
				flex-direction: column;
				padding: 0 11%;

				p {
					font-size: 0.3rem;
					margin-bottom: 1.5vmin;
					font-weight: 500;
				}
				span {
					font-size: 0.2rem;
				}
			}
			.button {
				width: 1.4rem;
				img {
					width: 100%;
				}
			}
		}
	}
}
