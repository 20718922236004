.confirm,
.top_up,
.confirm-left {
	--modal-img: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/popup/bg_confirm.png");
	// height: 100%;
	// width: 38%;
	// width: 4.5rem;
	width: 4.66rem;
	height: 5.55rem;
	background-position: top center;
	background-size: contain;

	.closeBtn {
		--close-dimension: 7%;
		top: 38%;
		right: -12%;
	}

	.modal-content {
		// background-color: red;
		// padding: 62% 11% 48%;
		padding: 2.9rem 11% 0.6rem;
		display: flex;
		justify-content: center;
		align-items: center;

		.content {
			text-align: center;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			.question {
				width: 100%;
				justify-content: center;
				display: flex;
				align-items: center;
				// flex-direction: column;

				span {
					font-family: Regular;
					font-size: 0.3rem;
					color: var(--color);

					img {
						width: 0.3rem;
						vertical-align: bottom;
						margin: 0 0.04rem;
					}
				}
			}

			label {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.2rem;
				color: var(--color);
				font-weight: bold;
			}

			.button {
				width: 1.4rem;
				margin-bottom: 0.3rem;
			}
		}
	}
}
