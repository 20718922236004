/* SETUP FOR KEEP ASPECT RATIO VIEWPORT */
#root {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 0.36rem;
}

#Main {
	position: fixed;
	margin: auto;
	top: 0 !important;
	right: 0;
	left: 0;
	bottom: 0;
	/* setup layout size */
	width: 13.34rem;
	height: 6.6rem;

	&:before {
		content: "";
		position: fixed;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-color: #000;
		/* Setup background for handle space on left and right or top and bottom. */
		/*
            background-image: var(--background);
            background-size: 13.34rem auto;
            background-position: center 50%;
            background-repeat: no-repeat;
        */
	}
}

/* Modal layout setup */
.react-responsive-modal-root {
	display: flex;
	align-items: center;
}

.react-responsive-modal-overlay {
	overflow: hidden;
}

.react-responsive-modal-container {
	width: 13.34rem !important;
	height: 6.6rem !important;
	display: flex;
	align-items: center;
	margin: 0 auto;
	font-size: 0.36rem;
}

.react-responsive-modal-modal {
	margin: 0 auto !important;
	max-width: none !important;
}
/* END SETUP FOR KEEP ASPECT RATIO VIEWPORT */
