.discount {
	// width: 56%;
	width: 6.9rem;
	height: 100%;
	// outline: 1px solid red;

	.closeBtn {
		top: 13%;
		right: -9%;
		--close-dimension: 6%;
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		// padding: 0;
		// padding-top: 18vmin;
		padding: 20% 5% 15%;

		.title {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 10%;
		}

		.content {
			width: 100%;
			height: 3.2rem;
			display: flex;
			flex-direction: column;

			.row {
				height: 30%;
				margin-bottom: 2%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.info {
					display: flex;
					align-items: center;
					width: 68%;
					// gap: 1vmax;

					.image {
						width: 30%;
						height: 100%;
						// width: 12vmin;
						// height: 12vmin;
						display: flex;
						align-items: center;
						justify-content: center;
						// margin: 0 auto 1vmax;
						// position: relative;

						background: var(--img-frame) center/contain no-repeat;
						color: #fee7a2;
						font-size: 0.3rem;
						font-weight: bold;
					}
					.message {
						font-weight: bold;
						width: 70%;
						font-size: 0.22rem;
					}
				}
				.btn-usediscount {
					width: 32%;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 100%;
					}
				}
			}
		}
	}
}
