@import 'assets/scss/variable';

.navigation-cont {
	position: relative;
	display: inline-block;
	padding-top: 1%;
	height: 77.5%;
	width: 29%;
	align-self: flex-start;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		height: 105%;
		background-image: var(--img-rope);
		background-size: cover;
		background-position: top center;
		overflow: auto;
		@include _px2vh("left", 74px / 2);
		@include _px2vh("width", 75px);
	}

	&.disabled {
		filter: grayscale(1);
		pointer-events: none;
	}

	.nav {
		margin-left: 0.12rem;
		height: calc(100% / 4);
		display: flex;
		justify-content: space-between;
		position: relative;
		-webkit-tap-highlight-color: transparent;

		&:hover,
		&.active {
			.close {
				opacity: 0;
			}
			.open{
				opacity: 1;
			}
		}

		.open {
			position: relative;
			height: 100%;
			opacity: 0;
		}

		.close {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			opacity: 1;
			// transition: opacity 300ms ease;
		}
	}
}
