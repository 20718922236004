.content-wrapper {
	width: 50%;
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: 100%;
	padding: 0 2%;

	@import "user";
	@import "draw";
	@import "button";
}
