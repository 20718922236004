@import "variable";
@import "fonts";
@import "reset";
@import "mixins";
@import "functions";
@import "lang";
@import "layout";

body * {
	color: var(--color);
}
#root,
#Main,
.content-container,
.home {
	height: 100%;
}

html,
body {
	--universal-color: #fff;
	--scroll-thumb-color: #ffffff;
	--scroll-track-color: #000000;
	color: var(--universal-color);
	background: hsl(0, 0%, 0%);
	height: 100%;

	* {
		-webkit-user-select: none;
		user-select: none;
		font-family: Regular;
		&::-webkit-scrollbar {
			width: 1vmax;
			height: 1vmax;
		}
		&::-webkit-scrollbar-button {
			width: 0px;
			height: 0px;
		}
		&::-webkit-scrollbar-thumb {
			background: var(--scroll-thumb-color);
			border: 0px solid #ffffff;
			border-radius: 50px;
		}

		&::-webkit-scrollbar-track {
			background: var(--scroll-track-color);
			border: 0px none #ffffff;
			border-radius: 50px;
		}

		&::-webkit-scrollbar-corner {
			background: transparent;
		}
	}
}

img {
	-webkit-touch-callout: none;
	user-select: none;
	-webkit-user-select: none;
}

#Main {
	background-size: 105%;
	background-position: 93% 35%;
	background-repeat: no-repeat;
	transition: background-image 500ms ease;
	animation: breathe 3s ease-in-out infinite alternate;
	overflow: hidden;

	// &::before {
	// 	content: "";
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/preview.jpeg") center
	// 		top/100% no-repeat;
	// 	z-index: 999;
	// 	opacity: 0.5;
	// }
}

.react-responsive-modal-overlay {
	background: rgba(0, 0, 0, 0.8);
}

@keyframes breathe {
	from {
		background-position: 93% 35%;
	}
	to {
		background-position: 95% 37%;
	}
}

@media only screen and (orientation: portrait) {
	html {
		transform: rotate(90deg);
		transform-origin: 50vmin 50vmin;
		width: 100vmax !important;
	}
}

/* FIXED FLICKER MODAL */
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal,
.react-responsive-modal-root {
	animation-fill-mode: forwards !important;
	top: 0 !important;
}
