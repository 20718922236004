.success {
	height: 100%;
	// width: 47%;
	width: 6.3rem;
	--modal-img: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/shrineofluck/popup/bg_congrats.png");

	.closeBtn {
		top: 19%;
		right: -10%;
		--close-dimension: 6%;
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		margin-top: 24%;
		padding: 5% 6% 12%;
		height: 67%;

		.title {
			width: 3rem;
			height: 0.44rem;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.content {
			height: 80%;
			position: relative;
			text-align: center;
			padding-right: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			.message,
			.sub-message {
				font-family: Bold;
				color: var(--color);
			}

			.image {
				--img-frame: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/draw_frame.png");
				width: 1.1rem;
				aspect-ratio: 1/1;
				position: relative;
				background-image: var(--img-frame);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 80%;
				}
			}

			.message {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				> span {
					font-family: Bold;
					font-size: 0.3rem;
				}

				.sub-message {
					font-size: 0.2rem;
					font-family: Regular;
				}
			}

			.button {
				width: 1.4rem;
			}
		}
	}
}
