.draw-container {
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	height: 66%;

	.draw {
		--rope: url("https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/draw_rope.png");
		position: relative;

		&:nth-child(n + 5) {
			&::before {
				background-position: bottom center;
			}
		}

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: var(--rope) center no-repeat;
			background-size: cover;
			position: absolute;
			top: 0;
		}

		.item-wrapper {
			position: relative;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: filter 0.1s ease-in-out;

			&.disabled {
				filter: grayscale(1) brightness(0.7) !important;
			}

			&.active {
				filter: drop-shadow(0 0 0.8vmax yellow);
			}

			img {
				position: relative;
				width: 100%;
				z-index: 2;
			}

			.prize-wrapper-img {
				position: absolute;
				top: 60%;
				left: 50%;
				width: 50%;
				transform: translate(-50%, -50%);
				z-index: 3;
			}
			.item {
				position: absolute;
				top: 60%;
				left: 50%;
				width: 40%;
				transform: translate(-50%, -50%);
				z-index: 3;
			}
			.item-name {
				// user-select: none;
				font-size: 0.2rem;
				position: absolute;
				top: 70%;
				left: 50%;
				width: 65%;
				padding: 0;
				background: none;
				text-align: center;
				color: rgba($color: #fff, $alpha: 0);
				transform: translate(-50%, -55%);
				transition: all 0.5s;
				z-index: 5;
				&.clicked {
					background: rgba($color: #793036, $alpha: 0.8);
					color: white;
					padding: 10% 0;
				}
			}
		}

		&:nth-child(1) {
			.item-wrapper {
				.item {
					top: 60%;
					width: 58%;
				}
			}
		}
	}
}
