.home {
	display: flex;
	position: relative;
	justify-content: space-between;

	.btn-play-music {
		position: absolute;
		left: 3%;
		top: 83%;
		width: 4.2%;
		z-index: 2;
		cursor: pointer;
		img {
			width: 100%;
		}
	}

	@import "title";
	@import "content";
}
